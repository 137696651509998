<template>
  <div class="p-20 bg-white">
    <div class="p-20 bg-f9 text-center rounded">
      <div class="font-bold mb-10">{{ detail.Title }}</div>
      <div>
        <a-space class="text-xs text-999">
          <span>
            <span>来源：</span>
            <span>{{ detail.Source }}</span>
          </span>
          <span>|</span>
          <span>
            {{ detail.Region.Name }}
          </span>
          <span>|</span>
          <span>
            {{ detail.Type.Name }}
          </span>
          <span>|</span>
          <span>
            {{ detail.PublicTime | formatterTime("YYYY-MM-DD") }}
          </span>
        </a-space>
      </div>
    </div>

    <div class="flex mt-40 justify-between items-center">
      <div class="text-lg font-bold mb-20">
        <span class="mr-10 text-primary align-middle">|</span>
        <span class="align-middle text-111">留言详情</span>
      </div>
      <a-select style="width: 120px" v-model="filter" @change="jumpPage(1)">
        <a-select-option :value="0">全部</a-select-option>
        <a-select-option :value="1">待审核</a-select-option>
        <a-select-option :value="2">已通过</a-select-option>
        <a-select-option :value="3">未通过</a-select-option>
      </a-select>
    </div>

    <div v-if="comments.length">
      <div
        class="mt-20 hover:shadow"
        v-for="dataSource in comments"
        :key="dataSource.id"
        @click="open1(dataSource)"
      >
        <div class="p-20 bg-white border border-eee">
          <div class="flex">
            <div
              class="
                mr-20
                border border-eee
                relative
                rounded-full rounded-br-none
                overflow-hidden
              "
              style="width: 48px; height: 48px"
            >
              <img
                class="w-full h-full object-contain"
                v-if="dataSource.companyLogo"
                :src="dataSource.companyLogo.url"
                alt=""
              />
            </div>
            <div style="width: calc(100% - 68px)" class="relative">
              <div class="ellipsis-2 mb-10 text-xs">
                <span class="font-bold text-primary mr-20">{{
                  dataSource.companyName
                }}</span>
                <a-space class="text-999 text-xs">
                  <span>
                    {{
                      dataSource.CreatedAt | formatterTime("YYYY-MM-DD HH:mm")
                    }}
                  </span>
                  <span>|</span>
                  <span>
                    <span v-if="dataSource.AuditStatus === 1">待审核</span>
                    <span v-if="dataSource.AuditStatus === 2">已通过</span>
                    <span v-if="dataSource.AuditStatus === 3">未通过</span>
                  </span>
                </a-space>
              </div>
              <div class="mb-20" style="word-wrap: break-word">
                {{ dataSource.Content }}
              </div>
              <div class="w-full text-right">
                <a-space v-if="dataSource.AuditStatus === 1">
                  <a-button type="primary" ghost @click="open(dataSource, 3)"
                    >审核拒绝</a-button
                  >
                  <a-button type="primary" @click="open(dataSource, 2)"
                    >审核通过</a-button
                  >
                </a-space>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <list-empty v-else></list-empty>
    <div v-if="comments.length" class="text-center py-20">
      <a-pagination
        :pageSize="limit"
        v-model="page"
        :total="total"
        @change="jumpPage"
      ></a-pagination>
    </div>

    <!-- <a-drawer
      title="提问详情"
      placement="right"
      width="600"
      :visible="visible"
      @close="visible = false"
    >
      <div class="p-20 bg-f9">
        <div class="flex">
          <div
            class="rounded mr-20 border border-eee relative"
            style="width: 214px; height: 160px"
          >
            <img
              class="w-full h-full object-contain"
              v-if="current.targetPhoto"
              :src="current.targetPhoto.url"
              alt=""
            />
            <div
              class="absolute bottom-0 right-0 text-xs bg-black text-white"
              style="padding: 5px 8px 5px 12px"
            >
              {{ current.targetTypeName }}
            </div>
            <div
              class="
                absolute
                top-20
                left-0
                bg-primary
                text-xs text-white
                rounded-tr-full rounded-br-full
              "
              style="padding: 5px 10px"
            >
              {{ current.statusName }}
            </div>
          </div>
          <div style="width: calc(100% - 234px)">
            <div class="font-bold ellipsis-2 mb-20">
              {{ current.targetName }}
            </div>
            <div class="ellipsis-3 text-666 text-sm mb-20">
              {{ current.targetDesc }}
            </div>
          </div>
        </div>
      </div>
      <div class="mt-40 text-lg font-bold">
        {{ current.title }}
      </div>
      <div class="text-right mt-20">
        <span class="text-xs text-999">提问是否对外展示 </span>
        <a-switch
          v-model="current.visible"
          @change="setCommentVisible(current.id, $event)"
        ></a-switch>
      </div>
      <div class="text-right mt-20">
        <a-button
          type="primary"
          @click="open(current)"
          v-if="current.status === 1"
          >审核</a-button
        >
      </div>
    </a-drawer> -->

    <a-modal
      v-model="modal"
      title="审核"
      okText="审核"
      cancelText="取消"
      @ok="evaluteProComment"
    >
      <a-form-model :model="evaluate">
        <a-form-model-item>
          <a-select v-model="evaluate.AuditStatus">
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">审核失败</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="evaluate.AuditStatus === 3">
          <gf-textarea
            :required="true"
            prop="Feedback"
            :max="300"
            :rows="5"
            v-model="evaluate.Feedback"
          ></gf-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  evaluatePolicyReview,
  getPolicyReviewList,
  getPolicy,
} from "@/api/api/policy.js";

export default {
  data() {
    return {
      detail: { Region: {}, Type: {} },
      data: {},
      comments: [],
      total: 0,
      page: 1,
      limit: 5,
      filter: 0,
      modal: false,
      evaluate: {},
      current: {},
      visible: false,
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.type = this.$route.query.type;
    this.jumpPage(1);
    this.getPolicy(this.id);
  },
  methods: {
    getPolicy(id) {
      getPolicy(id)
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {
          this.$message.error("获取列表失败");
        });
    },
    getPolicyReviewList(params) {
      params.policy_id = this.id;
      getPolicyReviewList(params).then(({ rows, count }) => {
        this.comments = rows;
        this.total = count;
        if (!this.data.id) {
          this.data = rows[0] || {};
        }
      });
    },
    jumpPage(e) {
      const params = {
        offset: (e - 1) * this.limit,
      };
      params.status = this.filter ? this.filter : undefined;
      params.limit = this.limit;
      this.getPolicyReviewList(params);
    },
    evaluteProComment() {
      evaluatePolicyReview(this.current.ID, this.evaluate).then(() => {
        this.$message.success("审核成功");
        this.jumpPage(this.page);
        this.modal = false;
      });
    },
    open(item, status) {
      this.evaluate = {
        AuditStatus: status,
        Feedback: "",
      };
      this.modal = true;
      this.visible = false;
      this.current = item;
    },
    open1(item) {
      this.visible = true;
      this.current = item;
    },
  },
};
</script>